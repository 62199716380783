import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
    Grid, Skeleton
} from "@mui/material";

// Contents Component
import DashboardMap from '../../contents/dashboardmap'
import InfiniteRank from '../../contents/infiniterank'
import MeshMeter from '../../contents/meshmeter'
import RainGraph from '../../contents/raingraph'
import ErrorMsg from '../../atoms/errormsg'
import LoadingMeter from '../../items/loadingmeter'

import { GetData, GetDataIntervalFiveMin } from '../../func/get_data_swr';

RiskDashboard.propTypes = {
    // 選択中の都道府県コード
    prfCode: PropTypes.number,
    // ランキングから選択した場所のメッシュコード
    selectMshCode: PropTypes.number,
    // 緯度経度
    latlng: PropTypes.array,
    // 選択されている日付
    chooseDate: PropTypes.string,
    // 選択されている日付のインデックス
    chooseNum: PropTypes.number,
    // 最新の解析雨量取得時間
    newestDate: PropTypes.string,
    // 日付リスト
    dateArray: PropTypes.array,
    // 現在選択されている時刻が予測か
    isForecast: PropTypes.bool,
    // ランキング選択時，メッシュコードを更新する関数
    setSelectMshCode: PropTypes.func,
    // // 位置選択時，メッシュコードを更新する関数
    // setSelectMshCode: PropTypes.func,
    // 位置選択時，緯度経度を更新する関数
    setLatlng: PropTypes.func,
    // キーとなる日付の状態を更新する関数
    updateChooseDate: PropTypes.func,
    // 日付のインデックスの状態を更新する関数
    updateChooseNum: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`
const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: calc(100dvh - 1rem - 48px);
    margin: 0.5rem 0 0 0.5rem;
`
const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 100%;
    margin: 0.2rem 0 0 0;
`
const CenterWideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    margin: 0.2rem 0.2rem 0 0.2rem;
`
const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: calc(100dvh - 1rem - 48px);
    margin: 0.5rem 0.5rem 0 5px;
`

/**
 * リスク分析テンプレート
 * @param {int} prfCode 都道府県コード
 * @param {array} latlng 緯度経度
 * @param {string} chooseDate 選択されている日付
 * @param {number} chooseNum 選択されている日付のインデックス値
 * @param {string} newestDate 最新の解析雨量取得時間
 * @param {array} dateArray 日付リスト
 * @param {bool} isForecast 現在表示されている時刻が予想か否か
 * @param {func} setLatlng 位置選択時，緯度経度を更新する関数
 * @param {func} updateChooseDate 選択している日付を更新する関数
 * @param {func} updateChooseNum 選択している日付のインデックス値を更新する関数
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function RiskDashboard(props) {
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()

    // 選択されたメッシュコードの静的情報取得
    let url = ``
    if (props.selectMshCode !== null) {
        // ランキングから選択されてメッシュコードがある場合
        if (props.selectBsnCode !== undefined && props.selectBsnCode !== null && props.selectBsnCode !== 0) {
            // メッシュコードが指定されていても水系コードも指定がある場合
            // 該当メッシュコードは水系コードに紐つくメッシュコードであるため
            // 取得される住所がずれてしまう。そのため水系コードがある場合は
            // 緯度経度からメッシュコードを再取得して静的情報を取得する必要がある
            url = `/rain/static/info/latlng/${props.prfCode}/${props.latlng[0]}/${props.latlng[1]}/`
        } else {
            url = `/rain/static/info/mshcode/${props.prfCode}/${props.selectMshCode}/`
        }
    } else {
        // 地図をクリックされてメッシュコードが未定の場合
        url = `/rain/static/info/latlng/${props.prfCode}/${props.latlng[0]}/${props.latlng[1]}/`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);


    const loadingItem = (size) => (
        <LeftWrapper>
            {
                [...Array(size)].map((elem, i) => (
                    <LoadingMeter key={`loading-one-${i}`} />
                ))
            }
        </LeftWrapper>
    );

    const leftItem = () => (
        <MeshMeter
            newestDate={props.newestDate}
            staticData={data}
            selectBsnCode={data.bsn_code}
            latlng={props.latlng}
            chooseDate={props.chooseDate}
            prfCode={props.prfCode}
            disasterId={disaster_id}
            basePointDate={props.basePointDate}
            isArchive={props.isArchive}
        />
    );

    const centerTopItem = () => (
        <DashboardMap
            selectCategory={props.selectCategory}
            setSelectCategory={props.setSelectCategory}
            prfCode={props.prfCode}
            disasterId={disaster_id}
            isArchive={props.isArchive}
            latlng={props.latlng}
            chooseDate={props.chooseDate}
            chooseNum={props.chooseNum}
            newestDate={props.newestDate}
            basePointDate={props.basePointDate}
            dateArray={props.dateArray}
            isForecast={props.isForecast}
            setLatlng={props.setLatlng}
            updateChooseDate={props.updateChooseDate}
            updateChooseNum={props.updateChooseNum}
            setSelectMshCode={props.setSelectMshCode}
            prevChooseNum={props.prevChooseNum}
        />
    );
    const centerBottomItem = () => (
        <RainGraph
            selectCategory={props.selectCategory}
            setSelectCategory={props.setSelectCategory}
            staticData={data}
            latlng={props.latlng}
            prfCode={props.prfCode}
            disasterId={disaster_id}
            basePointDate={props.basePointDate}
            isArchive={props.isArchive}
        />
    );
    const rightItem = () => (
        <RightWrapper>
            <InfiniteRank
                selectCategory={props.selectCategory}
                setSelectCategory={props.setSelectCategory}
                setSelectMshCode={props.setSelectMshCode}
                setSelectBsnCode={props.setSelectBsnCode}
                setLatlng={props.setLatlng}
                updateChooseDate={props.updateChooseDate}
                prfCode={props.prfCode}
                disasterId={disaster_id}
                basePointDate={props.basePointDate}
                isArchive={props.isArchive}
            />
        </RightWrapper>

    );

    // Loading
    if (loading) return (
        // <Grid container>
        //     <Grid item md={2}>
        //         {loadingItem(7)}
        //     </Grid>
        //     <Grid item md={8}>
        //         {centerTopItem()}
        //     </Grid>
        //     <Grid item md={2}>
        //         {rightItem()}
        //     </Grid>
        // </Grid>
        <AppWrapper>
            {!props.isFromWeb ?
                <>
                    {loadingItem(5)}
                    <CenterWrapper>
                        {centerTopItem()}
                        <></>
                    </CenterWrapper>
                    {rightItem()}
                </>
                :
                <CenterWideWrapper>
                    {centerTopItem()}
                    <></>
                </CenterWideWrapper>
            }

        </AppWrapper>
    )

    return (
        <AppWrapper>
            {!props.isFromWeb ?
                <>
                    <LeftWrapper>
                        {error ?
                            <ErrorMsg msg={'データが見つかりませんでした'} />
                            :
                            leftItem()
                        }
                    </LeftWrapper>
                    <CenterWrapper>
                        {centerTopItem()}
                        {error ?
                            <ErrorMsg msg={'データが見つかりませんでした'} />
                            :
                            centerBottomItem()
                        }
                    </CenterWrapper>
                    {rightItem()}
                </>
                :
                <CenterWideWrapper>
                    {centerTopItem()}
                    {error ?
                        <ErrorMsg msg={'データが見つかりませんでした'} />
                        :
                        centerBottomItem()
                    }
                </CenterWideWrapper>
            }
        </AppWrapper>
    )
}
