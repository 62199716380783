import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import RiskHeader from '../../contents/riskheader'
import ArchiveMenu from '../../contents/archive_menu'
import ArchiveRecentTimeLine from '../../contents/archive_recent_timeline'
import ArchiveDisasterTimeLine from '../../contents/archive_disaster_timeline'
import { LoadMutate } from '../../func/get_data_swr';
import { JSTToMMDDHHmm } from '../../func/datetoview.jsx'

import {
    Grid,
} from "@mui/material";


/**
 * アーカイブトップページ
 * @returns {JSX.Element} アーカイブトップページ
 */
export default function Archive(props) {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedDisasterId, setSelectedDisasterId] = useState('recent');
    const [selectedDisasterStatus, setSelectedDisasterStatus] = useState(null);
    const [latestCheckPoint, setLatestCheckPoint] = useState('');

    useEffect(() => {
        const time = new Date()
        props.setLastUpdateTime(JSTToMMDDHHmm(time))
    }, [])

    const reload = () => {
        LoadMutate(`/get_disaster_list/?pref_code=${props.prfCode}`)
    }

    return (
        <Grid container>
            <Grid item md={4}>
                {/* アーカイブ一覧 */}
                <ArchiveMenu
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    selectedDisasterId={selectedDisasterId}
                    setSelectedDisasterId={setSelectedDisasterId}
                    setSelectedDisasterStatus={setSelectedDisasterStatus}
                    prfCode={props.prfCode}
                    latestCheckPoint={latestCheckPoint}
                    reload={reload}
                />
            </Grid>
            <Grid item md={8}>
                <ContentsWrapper>
                    {selectedDisasterId === 'recent' ?
                        // 直近アーカイブタイムライン
                        <ArchiveRecentTimeLine
                            selectedIndex={selectedIndex}
                            setLatestCheckPoint={setLatestCheckPoint}
                            prfCode={props.prfCode} />
                        :
                        // 災害アーカイブタイムライン
                        <ArchiveDisasterTimeLine
                            selectedDisasterId={selectedDisasterId}
                            selectedDisasterStatus={selectedDisasterStatus}
                            setSelectedDisasterStatus={setSelectedDisasterStatus}
                            selectedIndex={selectedIndex}
                            prfCode={props.prfCode}
                            reload={reload} />
                    }
                </ContentsWrapper>
            </Grid>
        </Grid>
    )
}
const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 48px);
    background-color: #152451;
`

