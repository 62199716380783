import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import MeterSlider from '../../contents/meterslider'
import TelMapView from '../../contents/telmapview'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { JSTToMMDDHHmm, strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm } from '../../func/datetoview'

TelMapDashboard.propTypes = {
    // 選択しているコード(固定)
    chooseCode: PropTypes.number,
    // 選択している時間
    chooseDate: PropTypes.number,
    // 選択している時間を更新する関数
    setChooseDate: PropTypes.func,
    // 選択している都道府県コード
    prfCode: PropTypes.number,
    // 最初に選択している緯度経度
    latlng: PropTypes.array,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100dvh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    margin-top:10px;
    overflow-y: hidden;
    height: 100%;
`

/**
 * 河川水位マップテンプレート
 * @param {number} chooseDate 選択している時間(インデックス値)
 * @param {func} setChooseDate 選択している時間を更新する関数
 * @param {array} latlng 緯度経度
 * @param {int} prfCode 都道府県コード
 * @param {func} setLastUpdateTime 最終更新日時を更新する関数 
 * @returns {JSX.Element}
 */
export default function TelMapDashboard(props) {

    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()
    // 災害IDがあれば災害アーカイブと判定
    const isArchive = disaster_id !== undefined && disaster_id !== null
        && disaster_id !== '' && disaster_id !== 'recent'

    // 選択している時間
    const [chooseDate, setChooseDate] = useState(null)

    // チェックポイントがある場合、チェックポイントはUTCなのでJSTの文字列に変換してAPIコール
    let base_point_date = undefined
    if (checkpoint !== undefined) {
        base_point_date = strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm(checkpoint)
    }

    let url = `/wtl/slider/date/${props.prfCode}/${base_point_date}/`
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    useEffect(() => {
        if (!loading && !error && data !== undefined && data.newest_date !== undefined) {
            props.setLastUpdateTime(JSTToMMDDHHmm(data.newest_date))
        }
    }, [data])

    if (loading) {
        return (
            <></>
        )
    }

    if (error) {
        return (
            <></>
        )

    }

    return (
        <AppWrapper>
            <MeterSlider
                prfCode={props.prfCode}
                setChooseDate={setChooseDate}
                data={data}
            />
            <ContentsWrapper>
                <TelMapView
                    isFromWeb={props.isFromWeb}
                    latlng={props.latlng}
                    prfCode={props.prfCode}
                    chooseDate={chooseDate}
                    disasterId={disaster_id}
                    basePointDate={data.newest_date}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            </ContentsWrapper>
        </AppWrapper>
    )
}
