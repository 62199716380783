import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import BsnBtn from '../../contents/bsnbtn'
import RiverBoard from '../../contents/riverboard'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { JSTToMMDDHHmm, strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm } from '../../func/datetoview'

RiverDashboard.propTypes = {
    // 水系コード
    bsnCode: PropTypes.number,
    // 水系名
    bsnName: PropTypes.string,
    // 水系情報を更新する
    updateBsnStatus: PropTypes.func,
    // 選択中の都道府県コード
    prfCode: PropTypes.number,
    // // 選択中の都道府県コードを更新する
    // updatePrfCode: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100dvh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`
const LoadingWrapper = styled.div`
    width: 100vw;
    height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: white;
`
/**
 * 河川水位テンプレート
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {func} updateBsnStatus 選択した水系情報を更新する 
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function RiverDashboard(props) {
    const { bsnCode, bsnName, updateBsnStatus, prfCode, setLastUpdateTime } = props
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, prf_code, checkpoint } = useParams()
    // 災害IDがあれば災害アーカイブと判定
    const isArchive = disaster_id !== undefined && disaster_id !== null
        && disaster_id !== '' && disaster_id !== 'recent'

    // チェックポイントがある場合、チェックポイントはUTCなのでJSTの文字列に変換してAPIコール
    let base_point_date = undefined
    if (checkpoint !== undefined) {
        base_point_date = strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm(checkpoint)
    }

    // 災害アーカイブの場合も時刻の取得は共通
    let url = `/wtl/slider/date/${prfCode}/${base_point_date}/`
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    useEffect(() => {
        if (!loading && !error && data !== undefined && data.newest_date !== undefined) {
            setLastUpdateTime(JSTToMMDDHHmm(data.newest_date))
        }
    }, [data])

    if (loading) {
        return (
            <LoadingWrapper>読み込み中</LoadingWrapper>
        )
    }
    if (error) {
        return (
            <></>
        )
    }

    return (

        <AppWrapper>
            <BsnBtn
                bsnCode={bsnCode}
                updateBsnStatus={updateBsnStatus}
                prfCode={prfCode}
            />
            <ContentsWrapper>
                <RiverBoard
                    isFromWeb={props.isFromWeb}
                    bsnCode={bsnCode}
                    bsnName={bsnName}
                    prfCode={prfCode}
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    basePointDate={data.newest_date}
                    isArchive={isArchive}
                    setLastUpdateTime={props.setLastUpdateTime}
                />
            </ContentsWrapper>
        </AppWrapper>
    )
}
