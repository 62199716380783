import React, { useEffect } from 'react'
import axios from 'axios'

// Template components
import TelMapDashboard from '../../templates/telmapdashboard'
import { config } from '../../config.jsx'

/**
 * 河川水位計マップ画面
 * @param {array} latlng 緯度経度
 * @param {int} prfCode 都道府県コード
 * @param {func} setLastUpdateTime 最終更新日時を更新する関数 
 * @returns {JSX.Element} 河川水位マップテンプレート
 */
export default function TelMap(props) {

    useEffect(() => {
        // Mapの中心座標を取得
        axios.get(config.restUrl + `/col/loc/latlng/${props.prfCode}/`)
            .then(response => {
                console.log(' /col/loc/latlng/', response)
                props.setLatlng([response.data.results.latitude, response.data.results.longitude])
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <TelMapDashboard
            isFromWeb={props.isFromWeb}
            prfCode={props.prfCode}
            latlng={props.latlng}
            setLastUpdateTime={props.setLastUpdateTime}
        />
    )
}
