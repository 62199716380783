import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Routes, BrowserRouter as Router, Route, useParams } from 'react-router-dom'

// コンポーネント
import { PrivateRoute } from '../_basicauth/_component/Privateroute'
import Login from '../pages/Login'
import Top from '../pages/Top'
import Meter from '../pages/Meter'
import River from '../pages/River'
import MeterSlider from '../pages/MeterSlider'
import NotFound from '../pages/NotFound'
import TelMap from '../pages/TelMap'

import RiskHeader from '../contents/riskheader'
import ArcHeader from '../contents/archeader'
import ArcHeaderFromWeb from '../contents/archeader_from_web'
import FromEdisonHeader from '../contents/from_edison_header'
import FromWebHeader from '../contents/from_web_header'

// アーカイブ
import Archive from '../pages/Archive'
import ArchiveFromWeb from '../pages/Archive_from_web'

// 大分県データページコンポーネント
import Shelter from '../pages/Shelter'
import ShelterMap from '../pages/ShelterMap'
import Evac from '../pages/Evac'

import { DEFAULT_POSITION, SERVICE_NAME } from '../const/general';

const Wrapper = styled.div`
    text-align: center;
    background-color: #061027;
    width: 100vw;
    height: 100dvh;
    position: fixed;
`

function Apps() {

    // 都道府県コードのデフォルト値を設定
    let defaultPrefCode = 0
    if (localStorage.getItem("user") !== null) {
        const userData = JSON.parse(localStorage.getItem('user'))
        defaultPrefCode = userData['authorizedPref'][0]
    }
    // const uri = new URL(window.location.href);
    // setServiceName(SERVICE_NAME.PREIN)
    // if (uri.hostname === 'risk-edison.com/' ||
    //     uri.hostname === 'dgerv4m0q1jrp.cloudfront.net/') {
    //     setServiceName(SERVICE_NAME.EDISON)
    // }


    // TODO　後日削除予定
    // EDiSONからの遷移の場合、ヘッダーメニューをアーカイブのみするための仮の対応
    // EDiSONからの遷移であるかの判定フラグ
    const [isFromEdison, setIsFromEdison] = useState(false)
    // 都道府県コード
    const [prfCode, setPrfCode] = useState(defaultPrefCode)
    // サービスコード（EDiSON or PREIN）
    const [serviceName, setServiceName] = useState(defaultPrefCode)
    // 最終更新日時
    const [lastUpdateTime, setLastUpdateTime] = useState('--月--日 --:--')
    // 選択している緯度経度
    const [latlng, setLatlng] = useState([DEFAULT_POSITION.LAT, DEFAULT_POSITION.LNG])

    const RealTimePages = (route) => {
        return (
            <React.Fragment>
                <RiskHeader
                    prfCode={prfCode}
                    setPrfCode={setPrfCode}
                    setLatlng={setLatlng}
                    lastUpdateTime={lastUpdateTime}
                />
                {route}
            </React.Fragment>
        )
    };

    // TODO　後日削除予定
    // EDiSONからの遷移の場合、ヘッダーメニューをアーカイブのみするための仮の対応
    const FromEdisonPages = (route) => {
        return (
            <React.Fragment>
                <FromEdisonHeader
                    prfCode={prfCode}
                    setIsFromEdison={setIsFromEdison}
                    // setPrfCode={setPrfCode}
                    lastUpdateTime={lastUpdateTime}
                />
                {route}
            </React.Fragment>
        )
    };

    const FromWebPages = (route) => {
        return (
            <React.Fragment>
                <FromWebHeader
                    prfCode={prfCode}
                    setIsFromEdison={setIsFromEdison}
                    // setPrfCode={setPrfCode}
                    lastUpdateTime={lastUpdateTime}
                />
                {route}
            </React.Fragment>
        )
    };

    const ArchivePages = (route) => {
        return (
            <React.Fragment>
                <ArcHeader
                    prfCode={prfCode}
                    lastUpdateTime={lastUpdateTime}
                    isFromEdison={isFromEdison}
                />
                {route}
            </React.Fragment>
        )
    };

    const ArchiveFromWebPages = (route) => {
        return (
            <React.Fragment>
                <ArcHeaderFromWeb
                    prfCode={44}
                    lastUpdateTime={lastUpdateTime}
                />
                {route}
            </React.Fragment>
        )
    };

    const SimulationPages = (route) => {
        return (
            <React.Fragment>
                <ArcHeader
                    prfCode={prfCode}
                    lastUpdateTime={lastUpdateTime}
                    isFromEdison={isFromEdison}
                />
                {route}
            </React.Fragment>
        )
    };

    return (
        <Wrapper>
            <div className="col-sm-8 col-sm-offset-2">
                <Router>
                    <div>
                        <Routes>
                            {/* アーカイブメニューページ（直近・災害、共通） */}
                            <Route exact path='/archive' element={<PrivateRoute funcType={'open'} />}>
                                <Route exact path='/archive'
                                    element={RealTimePages(
                                        <Archive
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>

                            {/* アーカイブトップページ（注意報・警報情報）（直近・災害、共通） */}
                            <Route exact path='/archive/:disaster_id/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/archive/:disaster_id/:checkpoint'
                                    element={ArchivePages(
                                        <Top
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>

                            {/*  TODO　後日削除予定
                                    EDiSONからの遷移の場合、ヘッダーメニューをアーカイブのみするための仮の対応 */}
                            <Route exact path='/from_edison/archive'
                                element={<PrivateRoute funcType={'open'}
                                    target_path='/from_edison/archive'
                                    setIsFromEdison={setIsFromEdison} />}>
                                <Route exact path='/from_edison/archive'
                                    element={FromEdisonPages(
                                        <Archive
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>



                            {/*  ここから　WEBサイト：災害アーカイブからの遷移の場合、認証なして参照するための対応 */}
                            <Route exact path='/archive_from_web/:disaster_id'
                                    element={FromWebPages(
                                        <ArchiveFromWeb />)}
                                />
                            <Route exact path='/archive_from_web/:disaster_id/:checkpoint'
                                    element={ArchiveFromWebPages(
                                        <Top
                                            prfCode={44}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                                <Route exact path='/risk_from_web/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchiveFromWebPages(
                                        <Meter
                                            latlng={latlng}
                                            isFromWeb={true}
                                            prfCode={44}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                                <Route exact path='/river_from_web/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchiveFromWebPages(
                                        <River
                                            prfCode={44}
                                            isFromWeb={true}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                                <Route exact path='/telmap_from_web/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchiveFromWebPages(
                                        <TelMap
                                            latlng={latlng}
                                            isFromWeb={true}
                                            prfCode={44}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                                <Route exact path='/msriv_from_web/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchiveFromWebPages(
                                        <MeterSlider
                                            prfCode={44}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            {/*  ここまで　WEBサイト：災害アーカイブからの遷移の場合、認証なして参照するための対応 */}





                            {/* 直近アーカイブリスク分析（緯度経度指定） */}
                            <Route exact path='/risk/:disaster_id/:lat/:lng/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk/:disaster_id/:lat/:lng/:checkpoint'
                                    element={ArchivePages(
                                        <Meter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* 直近アーカイブリスク分析（緯度経度指定なし） */}
                            <Route exact path='/risk/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchivePages(
                                        <Meter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* 災害アーカイブリスク分析（緯度経度指定） */}
                            {/* <Route exact path='/risk/:disaster_id/:lat/:lng/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk/:disaster_id/:lat/:lng/:checkpoint'
                                    element={ArchivePages(
                                        <ArcMeter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route> */}
                            {/* 災害アーカイブリスク分析（緯度経度指定なし） */}
                            {/* <Route exact path='/risk/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchivePages(
                                        <ArcMeter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route> */}
                            <Route exact path='/river/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/river/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchivePages(
                                        <River
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* 災害アーカイブリスク分析（緯度経度指定なし） */}
                            <Route exact path='/telmap/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/telmap/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchivePages(
                                        <TelMap
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            <Route exact path='/msriv/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/msriv/:disaster_id/:prf_code/:checkpoint'
                                    element={ArchivePages(
                                        <MeterSlider
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            <Route exact path='/shelter/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/shelter/:disaster_id/:prf_code/:checkpoint' element={<Shelter prfCode={prfCode} setPrfCode={setPrfCode} />} />
                            </Route>
                            <Route exact path='/shelmap/:disaster_id/:prf_code/:checkpoint' element={< PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/shelmap/:disaster_id/:prf_code/:checkpoint' element={<ShelterMap prfCode={prfCode} setPrfCode={setPrfCode} />} />
                            </Route>
                            <Route exact path='/evac/:disaster_id/:prf_code/:checkpoint' element={<PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/evac/:disaster_id/:prf_code/:checkpoint' element={<Evac prfCode={prfCode} setPrfCode={setPrfCode} />} />
                            </Route>
                            {/* アーカイブ機能 */}

                            {/* リアルタイムトップページ（注意報・警報情報） */}
                            <Route exact path='/' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/'
                                    element={RealTimePages(
                                        <Top prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>

                            {/* リアルタイムリスク分析（緯度経度指定） */}
                            <Route exact path='/risk/:lat/:lng' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk/:lat/:lng'
                                    element={RealTimePages(
                                        <Meter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイムリスク分析（緯度経度指定なし） */}
                            <Route exact path='/risk' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/risk'
                                    element={RealTimePages(
                                        <Meter
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイム水位分析 */}
                            <Route exact path='/river' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/river'
                                    element={RealTimePages(
                                        <River
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイム水位地図 */}
                            <Route exact path='/telmap' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/telmap'
                                    element={RealTimePages(
                                        <TelMap
                                            latlng={latlng}
                                            prfCode={prfCode}
                                            setLatlng={setLatlng}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイム水位変化 */}
                            <Route exact path='/msriv' element={< PrivateRoute funcType={'open'} />}>
                                <Route exact path='/msriv'
                                    element={RealTimePages(
                                        <MeterSlider
                                            prfCode={prfCode}
                                            setLastUpdateTime={setLastUpdateTime}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイム避難所 */}
                            <Route exact path='/shelter' element={< PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/shelter'
                                    element={RealTimePages(
                                        <Shelter prfCode={prfCode}
                                        />)}
                                />
                            </Route>
                            {/* リアルタイム避難所地図 */}
                            <Route exact path='/shelmap' element={< PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/shelmap'
                                    element={RealTimePages(
                                        <ShelterMap prfCode={prfCode} />)}
                                />
                            </Route>
                            {/* リアルタイム避難情報 */}
                            <Route exact path='/evac'
                                element={<PrivateRoute funcType={'pref'} />}>
                                <Route exact path='/evac'
                                    element={RealTimePages(
                                        <Evac prfCode={prfCode} />)}
                                />
                            </Route>


                            {/* シミュレーション機能 */}
                            {/* <Route exact path='/simulation/:simulation_id/:checkpoint' 
                            element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/simulation/:simulation_id/:checkpoint' element={ <Top /> } />
                            </Route>
                            <Route exact path='/simulation' 
                            element={ <PrivateRoute funcType={'open'} />}>
                                <Route exact path='/simulation' element={ <Simulation />} />
                            </Route>
                            <Route exact path='/simulation/create' element={ <PrivateRoute funcType={'open'} />}>
                                <Route exact path='/simulation/create' element={ <SimulationCreate />} />
                            </Route>
                            <Route exact path='/simulation/:simulation_id/' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/simulation/:simulation_id/' element={ <Simulation />} />
                            </Route>
                            <Route exact path='/sim/risk/:lat/:lng/:simulation_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/sim/risk/:lat/:lng/:simulation_id/:checkpoint' element={ <Meter /> } />
                            </Route>
                            <Route exact path='/sim/risk/:simulation_id/:checkpoint' element={ < PrivateRoute funcType={'open'} /> }>
                                <Route exact path='/sim/risk/:simulation_id/:checkpoint' element={ <Meter /> } />
                            </Route> */}
                            {/* シミュレーション機能 */}
                            <Route path='/login' element={
                                <Login
                                    setPrfCode={setPrfCode}
                                    // TODO　後日削除予定
                                    // EDiSONからの遷移の場合、ログイン時の遷移先をアーカイブに固定するための仮の対応
                                    isFromEdison={isFromEdison}
                                />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </div>
                </Router>
            </div>
        </Wrapper>
    )
}

export default Apps;
