import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Atomic Components
import LinkBtn from '../../atoms/linkbtn/index.jsx'
import ErrorMsg from '../../atoms/errormsg/index.jsx'
import { GetData } from '../../func/get_data_swr.js';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 90vw;
    padding: 0 5vw;
    border-bottom: thin ridge #15335f;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
    display: flex;
`

export default function FromWebHeader() {

    // 災害IDをパラメータから受け取る
    const { disaster_id } = useParams()

    // 災害アーカイブ取得(都道府県コード44指定)
    const result_arc = GetData(`/get_disaster_list/?pref_code=44`);
    const arcData = result_arc.data
    const arcLoading = result_arc.loading
    const arcError = result_arc.error

    if (arcLoading) {
        return (
            <></>
        )
    }
    else if (arcError) {
        return (
            <Wrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    // アーカイブ名を反映
    const arc = arcData.filter(e => { return e.disaster_id === disaster_id })
    const msg = arc[0].disaster_name

    return (
        <Wrapper
            backColor={'#061027'}
        >
            <BtnWrapper>
                <LinkBtn
                    key={"0"}
                    text={msg}
                    url=''
                    isChoosed={true}
                    choosedBackColod={'#223C6D'}
                />
            </BtnWrapper>
        </Wrapper >
    )

}
