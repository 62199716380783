import React from "react";
import styled, { keyframes } from 'styled-components'
import { HoverChip } from '../../style/chip.js'
import { BoxFlexStart, BoxCenterStart } from '../../style/box.js'
import { GetData } from '../../func/get_data_swr.js';

import ErrorMsg from '../../atoms/errormsg/index.jsx'
import {
    dateToStrYYYYMMDD,
    dateToStrHHMM,
    dateToYYYYMMDDHHMM,
    utcDateToStrYYYYMMDDHHmmss,
    descTimeSort
} from '../../func/datetoview.jsx'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import TimelineDot from '@mui/lab/TimelineDot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Link, useParams } from 'react-router-dom'

/**
 * アーカイブした災害のチェックポイントタイムライン
 * @returns {JSX.Element}
 */
export default function ArchiveTimeLine() {

    // 災害IDをパラメータから受け取る
    const { disaster_id } = useParams()

    // 災害アーカイブチェックポイント取得(災害ID指定)
    const url = `/get_disaster_checkpoints/?disaster_id=${disaster_id}`
    const { data, loading, error } = GetData(url);

    if (loading) {
        return (
            <Wrapper>
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }

    function recentList() {
        let objList = []
        let obj = {}
        let array = []
        for (let i = 0; i < data.length; i++) {
            const checkpoint_org = data[i].checkpoint_date
            const checkpoint = utcDateToStrYYYYMMDDHHmmss(data[i].checkpoint_date)
            const str_checkpoint_date = dateToStrYYYYMMDD(data[i].checkpoint_date)
            const str_checkpoint_time = dateToStrHHMM(data[i].checkpoint_date)

            if (i === 0) {
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org': checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }

            if (i !== 0 &&
                str_checkpoint_date !== dateToStrYYYYMMDD(data[i - 1].checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わった場合
                obj.time_list = array
                objList.push(obj)

                obj = {}
                array = []
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org': checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }
            if (i !== 0
                && str_checkpoint_date === dateToStrYYYYMMDD(data[i - 1].checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わっていない場合
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
            }
            if (i === data.length - 1) {
                obj.time_list = array
                objList.push(obj)
            }
        }
        const sortedObjList = objList.sort((a, b) => descTimeSort(a, b))
        return sortedObjList
    }
    return (

        <ContentsWrapper>
            <Timeline sx={{ overflowY: 'auto', display: 'block' , padding:'6px 30%'}}>
                {recentList().map((e, i) => (
                    <>
                        <TimelineItem
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.3,
                                },
                            }}
                        >
                            <TimelineOppositeContent
                                sx={{ m: '27px auto 0 0', minHeight: '50px' }}
                                color="white">
                                {e.checkpoint_date}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" sx={{ margin: '6px 0px' }}>
                                    <CalendarTodayIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{ m: '25px auto 0 0' }}>
                                <BoxFlexStart>
                                    {e.time_list.map((f) => (
                                        <Link to={`/archive_from_web/${data[0].disaster_id}/${f.checkpoint}`}>
                                            <HoverChip label={f.checkpoint_time} />
                                        </Link>
                                    ))}
                                </BoxFlexStart>
                            </TimelineContent>
                        </TimelineItem>
                    </>
                ))}
            </Timeline>

        </ContentsWrapper>
    )
}

// 災害一覧ラッパー ///////////////////////////////////////////////////
const LoadingAnimation = keyframes`
    to {
        clip-path: inset(0 -0.5ch 0 0)
    }
`
const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 48px);
    background-color: #152451;
`
const Wrapper = styled.div`
    margin: 5vh 20vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`
