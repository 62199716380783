import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ErrorMsg from '../../atoms/errormsg'
import TitleH6Left from '../../atoms/title_h6_left'
import LoadingMeter from '../../items/loadingmeter'
import Meter from '../../items/meter'
import MtrInfo from '../../items/mtrinfo'

// Contents Component
import RiverGraph from '../rivergraph'

import { dateToStrYYYYMMDDHHmmss, JSTToMMDDHHmm } from '../../func/datetoview'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { get_colorclass_from_class } from '../../func/getcolor'

// 水位系一覧のラッパー
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-left: 5vw;
    padding-right: 1rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    overflow-y: scroll;
    height: 100%
`
const ListInnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`
// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    height: auto;
    overflow-y: scroll;
`

/**
 * 河川水位画面を構成するコンポーネント
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} basePointDate チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function RiverBoard(props) {
    const { bsnCode, bsnName, prfCode = 0, disasterId = '', checkpoint = '', basePointDate, isArchive = false } = props

    // 選択中の水位計のID
    const [telCode, setTelCode] = useState(null)
    // 選択中の水位計の種類
    const [mtrCategory, setMtrCategory] = useState('')

    function updateChooseTel(code, category) {
        setTelCode(code)
        setMtrCategory(category)
    }
    const onclickFunc = (props) => {
        updateChooseTel(props[0], props[1])
    }

    // 基準日を文字列に変換
    const base_point_date = dateToStrYYYYMMDDHHmmss(basePointDate)

    // 水位計情報を取得
    let url = `/wtl/list/info/${bsnCode}/${base_point_date}/`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/wtl/list/info/${props.prfCode}/${bsnCode}/${base_point_date}/`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    useEffect(() => {
        if (!loading && !error &&
            data !== undefined && data.results !== undefined &&
            data.results.length > 0) {
            // データが更新されたら最終更新日時を更新
            props.setLastUpdateTime(JSTToMMDDHHmm(data.results[0].tel_date))
        }
    }, [data])


    if (loading) {
        return (
            <>
                <ListWrapper>
                    <TitleH6Left text={`${bsnName}水系水位(パーセント)`} />
                    {
                        [...Array(15)].map((elem, i) => (
                            <LoadingMeter key={`loading-mtr-${i}`} />
                        ))
                    }
                </ListWrapper>
                <GraphWrapper>
                    <></>
                </GraphWrapper>
            </>
        )
    }
    if (error) {
        return (
            <React.Fragment>
                <TitleH6Left text={`${bsnName}水系水位(パーセント)`} />
                <ErrorMsg msg={'データが見つかりませんでした'} />
                <GraphWrapper>
                    <ErrorMsg text={'データが見つかりませんでした'} />
                </GraphWrapper>
            </React.Fragment>
        )
    }

    let selectedTelData = data.results.filter(elem => elem.tel_code === telCode)[0]

    // 水位計未選択時に先頭の水位計を自動で選択する
    // 最新のデータ内に選択中のTelCodeがあるかを判定して存在しなければ selectedTelData にdata.results[0]
    if (!telCode || !data.results.some(elem => elem.tel_code === telCode)) {
        updateChooseTel(data.results[0].tel_code, 'tel')
        selectedTelData = data.results.filter(elem => elem.tel_code === data.results[0].tel_code)[0]
    }

    // console.log('data')
    // console.log(data)
    // console.log('telCode')
    // console.log(telCode)
    // console.log('selectedTelData')
    // console.log(selectedTelData)

    // グラフの設定
    const mtrBorder = [
        selectedTelData.green_border,
        selectedTelData.yellow_border,
        selectedTelData.red_border,
        selectedTelData.purple_border,
        selectedTelData.max_border
    ]
    const mtrLabel = [
        '水防団待機',
        '氾濫注意',
        '避難判断',
        '氾濫危険',
        '計画高'
    ]

    return (
        <React.Fragment>
            <ListWrapper>
                <TitleH6Left text={`${bsnName}水系水位(パーセント)`} />
                <ListInnerWrapper>
                    {
                        data.results.map((elem, i) => (
                            <Meter
                                key={elem.tel_code}
                                headtitle={`${elem.tel_name} - ${elem.river_name}`}
                                subtitle={elem.tel_date ? JSTToMMDDHHmm(elem.tel_date) : ''}
                                value={elem.value !== null ? elem.value : '欠測'}
                                colorclass={get_colorclass_from_class(elem.colorclass)}
                                is_btn={telCode !== elem.tel_code}
                                isChoosed={telCode === elem.tel_code}
                                unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
                                changes={elem.diff_flag}
                                updateStateFunc={onclickFunc}
                                funcProps={[elem.tel_code, 'tel']}
                            />
                        ))
                    }
                </ListInnerWrapper>
            </ListWrapper>

            <GraphWrapper>
                <MtrInfo
                    isFromWeb={props.isFromWeb}
                    mtrName={selectedTelData.tel_name}
                    basName={`${selectedTelData.river_system_name}水系`}
                    riverName={selectedTelData.river_name}
                    mtrDetail={selectedTelData.place_info}
                    mtrLat={selectedTelData.latitude}
                    mtrLng={selectedTelData.longitude}
                    mtrAddress={selectedTelData.address}
                    mtrDiffFlag={selectedTelData.diff_flag}
                    mtrValue={selectedTelData.act_value}
                    colorclass={selectedTelData.colorclass}
                    mtrBorder={mtrBorder}
                    mtrUnit={'m'}
                    mtrLabel={mtrLabel}
                    isEmptyMsg={'欠測'}
                    prfCode={prfCode}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
                <RiverGraph
                    telCode={telCode}
                    mtrCategory={mtrCategory}
                    mtrBorder={mtrBorder}
                    mtrLabel={mtrLabel}
                    basePointDate={base_point_date}
                    isArchive={isArchive}
                    prfCode={prfCode}
                />
            </GraphWrapper>
        </React.Fragment>
    )
}

